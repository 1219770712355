var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-upload",
    {
      attrs: {
        name: "file",
        showUploadList: false,
        accept:
          ".zip," +
          (_vm.defaultType.indexOf("JPG") > -1 ? ".jpg," : "") +
          (_vm.defaultType.indexOf("PSD") > -1 ? ".psd," : "") +
          (_vm.defaultType.indexOf("PNG") > -1 ? ".png," : ""),
        multiple: true,
        "before-upload": _vm.beforeUpload,
        "custom-request": _vm.handleRequest,
      },
    },
    [
      !_vm.isTable
        ? _c(
            "a-button",
            {
              staticClass: "mr-3",
              attrs: { loading: _vm.loading, icon: "upload", type: "primary" },
            },
            [_vm._v(_vm._s(_vm.btnText) + " ")]
          )
        : _c(
            "a-spin",
            { attrs: { spinning: _vm.loading } },
            [
              _c("a-icon", {
                staticStyle: { "font-size": "24px" },
                attrs: { slot: "indicator", type: "loading", spin: "" },
                slot: "indicator",
              }),
              _c("a", [_vm._v(_vm._s(_vm.btnText))]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }